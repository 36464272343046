<template>
  <div class="roleHeader grid-child-items-wrapper w-full text-base-content">
    <div
      v-if="tab!='check-output-form-editor'"
      class="roleHeader__item font-semibold text-sm w-24 text-center"
    >
      Block Visibility
    </div>
    <div class="grid-child-items-inner">
      <div /> <!-- Placeholder -->
      <div
        v-if="tab!='check-output-form-editor'"
        class="flex gap-4 w-min text-base-content justify-end"
      >
        <HeaderBlock
          v-for="(role, id) in roles"
          :key="id"
          class="roleHeader__item"
          :title="role"
          :changeUi="changeUi"
          :currentTab="tab"
        />
      </div>
      <div
        v-else
        class="flex gap-4 w-min text-base-content justify-end mr-6"
      >
        <HeaderBlock
          v-for="(role, id) in rolesOutput"
          :key="id"
          class="roleHeader__item"
          :title="role"
          :changeUi="changeUi"
           :currentTab="tab"
        />
      </div>
      <div class="roleHeader__item font-semibold text-sm w-24" v-if="tab!='check-output-form-editor'">Use In Case Creation</div>
      <div class="roleHeader__item font-semibold text-sm w-24" v-if="tab!='check-output-form-editor'">Report Visibility</div>
    </div>
    <div /> <!-- Placeholder -->
  </div>
</template>

<script>
import HeaderBlock from "./components/header-block.vue";

export default {
  name: 'field-roles-header',
  components: {
    HeaderBlock,
  },
  data() {
    return {
      roles: [
        'Analyst',
        'Subject',
        'Client',
        'Referee',
      ],
      rolesOutput: [
        'Analyst',
        'Client',
      ],
      inCheckRole:null
    }
  },
  props:{
    changeUi:{
      type:Boolean,
    },
    tab: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>
.item-2 {
  margin-right: 6px;
}
.item-1{
  margin-right: 7px;
}
.item-0{
  margin-right: 6px;
}

.grid-child-items-wrapper {
  display: grid;
  grid-template-columns: 100px 1fr 50px;
}

.grid-child-items-inner {
  display: grid;
  grid-template-columns: 1px 1fr 100px 220px;
  max-width: 900px;
}
</style>
