<template>
    <div v-if="checkData" class="shadow-md relative rounded-2xl bg-card-bg flex gap-6 flex-col text-base-content">
        <div class="p-3 panel-head bg-primary-300 font-bold flex gap-3 justify-between items-center rounded-t-2xl text-white-text">
            Form Details
            <button v-if="checkPermission('form.edit')" v-allow="'form.delete'" @click="deleteForm(checkData)" class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm">
                <font-awesome-icon icon="trash" />
            </button>
        </div>
        <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
            <div class="flex flex-col gap-3 p-3">
                <div class="flex w-full gap-6 items-stretch">
                    <div class="flex flex-col w-2/5 gap-3">
                        <div class="flex flex-col gap-3 w-full">
                            <FormulateInput
                                v-model="checkData.name"
                                @input="handleInput"
                                :disabled="!checkPermission('form.edit')"
                                class="w-full"
                                label="Form Name"
                                placeholder="Form Name"
                                type="text"
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            />
                        </div>
                        <!-- <div class="flex flex-col gap-3">
                            <span class="text-gray-700 text-md font-bold"> SLA Period</span>
                            <div class="flex items-center gap-5">
                                <Input inputClass="bg-gray-100 border-0 rounded-xl" @keypress="isNumber" type="number" class="h-10 w-full" placeholder="Field Name" v-model="checkData.sla" />
                                <span class="text-gray-700 text-md font-semibold w-1/4">Days</span>
                            </div>
                        </div> -->
                        <div v-if="checkData && checkData.parameters && checkData.parameters.parameters">
                            <span class="text-md"> Check Parameters </span>
                            <div class="mt-2">
                                <div class="rounded flex gap-2 items-center" v-for="params in checkData.parameters.parameters" :key="params.name">
                                    <div class="bg-indigo-200 py-1 px-2 rounded">
                                        {{ params.name }}
                                    </div>
                                    <input 
                                        v-model="params.value" 
                                        @input="handleInput"
                                        :disabled="!checkPermission('form.edit')" 
                                        :type="params.value_type" 
                                        min="0" 
                                        @keypress="isNumber" 
                                        class="text-center border border-b border-gray-200 h-8 w-12 rounded flex items-center justify-center" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mt-auto">
                            <span class="text-md ml-4"> Read Only </span>
                            <div class="mt-5">
                                <div class="flex items-center ml-4">
                                    <span class="toggle-label-btn pr-1">Disable</span>
                                    <div class="toggle-btn-cnt">
                                        <label class="switch">
                                            <input 
                                                type="checkbox" 
                                                @click="handleToggle"  
                                                :checked="checkData.read_access_only"
                                            >
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <span class="toggle-label-btn">Enable</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3 w-3/5">
                        <FormulateInput
                            v-model="entityTypesModel"
                            @input="handleInput"
                            validation="required"
                            class="w-full"
                            label="Entity Type"
                            placeholder="Select Entity Type"
                            type="multi-select"
                            variant="alt"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            :options="entityTypeOptions"
                            :loading="isEntityTypesLoading"
                            :config="{
                                ...ENTITY_TYPES_DROPDOWN_CONFIG,
                                limit: 4,
                            }"
                            :disabled="!checkPermission('form.edit')"
                        />
                        <FormulateInput
                            v-model="checkData.description"
                            @input="handleInput"
                            :disabled="!checkPermission('form.edit')"
                            class="w-full"
                            label="Form Description"
                            placeholder="Description"
                            type="textarea"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            :rows="5"
                        />
                    </div>
                </div>
                <div class="mt-2 flex justify-end max-w-full gap-4" v-allow="'form.edit'" >
                    <Button 
                        @click="saveForm()" 
                        :disabled="!isDirty || hasErrors"
                        class="btn-primary float-right mt-4" 
                        text="Save" 
                    />
                </div>
            </div>
        </FormulateForm>
    </div>
</template>

<script>
// * We use same methods for both form and checks so we can use same events here
import Button from "@/components/button";
import { isNumber } from "@/plugins/functions";
import { checkPermission } from "@shared/utils/functions"
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";
import { mapActions, mapState } from 'vuex';

export default {
    name: "form-details",
    components: {
        Button,
    },
    props: {
        checkData: {
            type: Object,
            default: null,
        },
        handleReadAccessMethod:{
            type: Function,
            default: ()=>{}
        }
    },
    data() {
        return {
            ENTITY_TYPES_DROPDOWN_CONFIG,
            isEntityTypesLoading: false,
        }
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.formsAdmin.isDirty,
        }),
        entityTypesModel: {
            get() {
                return this.checkData.entity_types;
            },
            set(val) {
                this.checkData.entity_types = val;
            },
        },
    },
    mounted(){
        this.fetchEntityTypeOptions();
    }, 
    methods: {
        ...mapActions([
            'fetchEntityTypeList',
            'handleFormDataChange'
        ]),
        handleInput() {
            this.handleFormDataChange(this.checkData)
        },
        checkPermission,
        isNumber,
        saveForm() {
            this.$emit("handleSaveCheck");
        },
        deleteForm(checkDetails) {
            this.$emit("handleDeleteCheck", checkDetails);
        },
        handleToggle(e){
            this.checkData.read_access_only = e.target.checked
            this.handleReadAccessMethod(this.checkData.id , e.target.checked)
            this.handleInput();
            return this.checkData.read_access_only
        },
        async fetchEntityTypeOptions() {
            this.isEntityTypesLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList();
            }
            this.isEntityTypesLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
  .switch {
        position: relative;
        display: inline-block;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: 44px;
        height: 21px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 5px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .toggle-btn-cnt{
        display: flex;
        align-items: center;
        width: 49px;
    }
    .toggle-label-btn{
        font-size: 13px !important;
    }
</style>
